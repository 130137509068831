
































import Vue from "vue";
import colorSquare from "./colorSquare.vue";
import formatter from "../colors/formatter";
import clipboardier from "../misc/clipboardier";
export default Vue.extend({
  name: "currentColor",
  components: {
    colorSquare
  },
  computed: {
    primaryHueColor() {
      return this.$store.state.primaryHue.color;
    },
    primaryHueName() {
      return this.$store.state.primaryHue.name;
    },
    currentColor() {
      return this.$store.getters.getColorInSpace("hsl");
    },
    matchedColor() {
      return this.$store.state.match.color;
    }
    matchedName() {
      return this.$store.state.match.name;
    }
    matchedDifference() {
      return this.$store.state.match.difference;
    }
  },
  methods: {
    onCopyClick(evt): void {
      evt.preventDefault();
      clipboardier.copyToClipboard(formatter.formatVariable(this.matchedName, this.matchedColor));
    }
  }
});
