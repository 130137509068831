















import Vue from "vue";
import pasteBox from "./display/pasteBox.vue";
import spacesList from "./spaces/spacesList.vue";
import currentColor from "./display/currentColor.vue";
export default Vue.extend({
  name: "app",
  components: {
    pasteBox,
    spacesList,
    currentColor,
  },
});
