




import Vue from "vue";
import formatter from "/colors/formatter";
export default Vue.extend({
  name: "colorSquare",
  props: {
    color: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
  },
  computed: {
    bgStyle() {
      const colorString = formatter.formatColor(this.color);
      return `background-color: ${colorString};`;
    },
  },
});
