
















import Vue from "vue";
import spacePart from "./spacePart.vue";
import { spaces } from "./spacesConfig";
import formatter from "../colors/formatter";
import clipboardier from "../misc/clipboardier";
export default Vue.extend({
  name: "spaceForm",
  components: {
    spacePart,
  },
  props: {
    space: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      parts: spaces.get(this.space).parts,
    };
  },
  methods: {
    onCopyClick(evt): void {
      evt.preventDefault();
      const colorValue = this.$store.getters.getColorInSpace(this.space);
      clipboardier.copyToClipboard(formatter.formatColor(colorValue));
    },
  },
});
