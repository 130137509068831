









import Vue from "vue";
import spaceForm from "./spaceForm.vue";
export default Vue.extend({
  name: "spacesList",
  components: {
    spaceForm,
  },
});
